import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/views/equipment/alert/alert'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 存储token
    token: '',
    userName: '',
    subtitle: '',
    userId: '',
    code: '',
    customerCode: '',
    // 设备sn
    deviceId: '',
    // 监听选择设备变量
    chooseDevice: false,
    // 管理级别变量
    managerIndex: '',
    // 存放index
    confirmIndex: '',
    dev_category: '',
    dev_type: '',
    dev_name: '',
    chooseDate: '',
    dateTotal: '',
    // 0 无子列表 1 有子列表
    mt_type: '',
    // 0 受电 1发电
    dev_p_type: ''

  },
  getters: {
    getToken (state) {
      return state.token || localStorage.getItem('token') || ''
    },
    getUserName (state) {
      return state.userName || localStorage.getItem('userName') || ''
    },
    getSubtitle (state) {
      return state.subtitle || localStorage.getItem('subtitle') || ''
    },
    getUserId (state) {
      return state.userId || localStorage.getItem('userId') || ''
    },
    getCode (state) {
      return state.code || localStorage.getItem('code') || ''
    },
    getCustomerCode (state) {
      return state.customerCode || localStorage.getItem('customerCode') || ''
    },
    getDeviceId (state) {
      return state.deviceId || localStorage.getItem('deviceId') || ''
    },
    getConfirmIndex (state) {
      return state.confirmIndex || localStorage.getItem('confirmIndex') || ''
    },
    getDev_category (state) {
      return state.dev_category || localStorage.getItem('dev_category') || ''
    },
    getDev_type (state) {
      return state.dev_type || localStorage.getItem('dev_type') || ''
    },
    getDevName (state) {
      return state.dev_name || localStorage.getItem('dev_name') || ''
    },
    getChooseDate (state) {
      return state.chooseDate || localStorage.getItem('chooseDate') || ''
    },
    getDateTotal (state) {
      return state.dateTotal || localStorage.getItem('dateTotal') || ''
    },
    getManagerIndex (state) {
      return state.managerIndex || localStorage.getItem('managerIndex') || ''
    },
    getMt_type (state) {
      return state.mt_type || localStorage.getItem('mt_type') || ''
    },
    getDev_p_type (state) {
      return state.dev_p_type || localStorage.getItem('dev_p_type') || ''
    }
  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token)
      // console.log('store、localstorage保存token成功！')
    },
    delToken (state) {
      state.token = ''
      localStorage.removeItem('token')
    },
    // 可选
    setUserInfo (state, userName) {
      state.userName = userName
      localStorage.setItem('userName', userName)
    },
    // 副标题
    setSubtitle (state, subtitle) {
      state.subtitle = subtitle
      localStorage.setItem('subtitle', subtitle)
    },
    //
    setUserId (state, userId) {
      state.userId = userId
      localStorage.setItem('userId', userId)
    },
    //
    setCode (state, code) {
      state.code = code
      localStorage.setItem('code', code)
    },
    setCustomerCode (state, customerCode) {
      state.customerCode = customerCode
      localStorage.setItem('customerCode', customerCode)
    },
    setDeviceId (state, deviceId) {
      state.deviceId = deviceId
      localStorage.setItem('deviceId', deviceId)
    },
    changeDevice (state, status) { //  重复赋值
      state.chooseDevice = status
      // console.log('11')
    },
    setManagerIndex (state, managerIndex) { //  重复赋值
      state.managerIndex = managerIndex
      localStorage.setItem('managerIndex', managerIndex)
    },
    setConfirmIndex (state, confirmIndex) { //  重复赋值
      state.confirmIndex = confirmIndex
      localStorage.setItem('confirmIndex', confirmIndex)
    },
    setDev_category (state, dev_category) { //  重复赋值 设备类型 4 逆变器
      state.dev_category = dev_category
      localStorage.setItem('dev_category', dev_category)
    },
    setDev_type (state, dev_type) { //  重复赋值 设备类型 4 逆变器
      state.dev_type = dev_type
      localStorage.setItem('dev_type', dev_type)
    },
    setDevName (state, dev_name) { //  重复赋值
      state.dev_name = dev_name
    },
    setChooseDate (state, chooseDate) { //  重复赋值
      state.chooseDate = chooseDate
      // console.log('fuzhi')
    },
    setDateTotal (state, dateTotal) { //  重复赋值
      state.dateTotal = dateTotal
      localStorage.setItem('dateTotal', dateTotal)
      // console.log('fuzhi')
    },
    setMt_type (state, mt_type) { //  重复赋值
      state.mt_type = mt_type
      localStorage.setItem('mt_type', mt_type)
    },
    setDev_p_type (state, dev_p_type) { //  重复赋值
      state.dev_p_type = dev_p_type
      localStorage.setItem('dev_p_type', dev_p_type)
    }
  },
  actions: {
  },
  modules: {
  }
})
